.lp-slideshow,
.lp-slideshow * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.lp-slideshow {
  width: 100%;
}

.lp-slideshow > .container {
  position: relative;
  margin: auto;
  width: 100%;
}

.lp-slideshow > .container > .slide {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  opacity: 0;
  transition: opacity 0.6s ease;
  -webkit-transition: opacity 0.6s ease;
  -moz-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
}

.lp-slideshow > .container > .slide.active {
  opacity: 1;
}

.lp-slideshow > .container > .slide > .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lp-slideshow > .container > .slide > .number-text {
  color: #fff;
  font-size: 1rem;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.lp-slideshow > .container > .slide > .caption-text {
  color: #fff;
  font-size: 1.3rem;
  padding: 8px 12px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.lp-slideshow > .container > .prev,
.lp-slideshow > .container > .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  font-weight: bold;
  transition: background-color 0.6s ease;
  -webkit-transition: background-color 0.6s ease;
  -moz-transition: background-color 0.6s ease;
  -o-transition: background-color 0.6s ease;
}

.lp-slideshow:hover > .container > .prev,
.lp-slideshow:hover > .container > .next {
  background-color: rgba(0, 0, 0, 0.2);
}

.lp-slideshow:hover > .container > .prev:hover,
.lp-slideshow:hover > .container > .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.lp-slideshow > .container > .next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.lp-slideshow > .container > .prev {
  left: 0;
  border-radius: 0 3px 3px 0;
}

.lp-slideshow > .dot-container {
  text-align: center;
  margin: 1rem auto;
}

.lp-slideshow > .dot-container > .dot {
  height: 15px;
  width: 15px;
  margin: 0 0.4rem;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  -webkit-transition: background-color 0.6s ease;
  -moz-transition: background-color 0.6s ease;
  -o-transition: background-color 0.6s ease;
}

.lp-slideshow > .dot-container > .dot.active,
.lp-slideshow > .dot-container > .dot:hover {
  background-color: #717171;
}

.lp-slideshow > .dot-container > .dot:not(.active):hover {
  cursor: pointer;
}
